import React, { useState } from "react";
import { signIn } from "next-auth/client";
import * as Sentry from "@sentry/browser";

const SignIn = () => {
  const [isLoading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    try {
      signIn("google", { callbackUrl: "/" });
    } catch (error) {
      const scope = new Sentry.Scope();
      scope.setTag("page", "signin");
      scope.setTag("handler", "signin");
      Sentry.captureException(error, scope);
    }
  };

  return (
    <div>
      <section className="bg-white">
        <div className="mx-auto max-w-screen-lg flex justify-center items-center relative md:h-full">
          <div className="w-full flex flex-col lg:flex-row text-gray-800 mb-32 sm:mb-0 my-12 sm:my-8 px-2 sm:px-0">
            <div className="flex px-2 items-center justify-center mt-8 sm:mt-0">
              <img src="/fsg-sign-in.png" alt="full-stack-gate" />
            </div>
            <div className="flex flex-col px-2 mb-16 mt-8 sm:mb-56 md:mb-16 sm:px-6 items-center md:items-start justify-center">
              <div className="flex">
                <h1 className="text-xl lg:text-3xl text-color font-black font-alegreya">
                  Ebook Full Stack Gate
                </h1>
              </div>
              <div className="flex mt-2 mb-8">
                <h2 className="text-md lg:text-xl text-color">
                  Silahkan sign in untuk mulai membaca
                </h2>
              </div>
              <div className="flex">
                <button
                  onClick={handleClick}
                  disabled={isLoading}
                  className="my-3 bg-white transition duration-150 ease-in-out hover:border-white hover:text-white hover:bg-gray-900 rounded border border-gray-300 text-gray-800 px-10 py-2 text-xl"
                >
                  {isLoading ? "Signing in..." : "Sign in with Google"}
                </button>
              </div>
              <div className="flex my-8">
                <h2 className="text-sm lg:text-base text-gray-500">
                  Powered by <a href="https://nextpage.devsaurus.com">NextPage</a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignIn;
